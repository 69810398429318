'use client'

import React from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { Stars, Sparkles, Moon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { useUser } from '@/contexts/UserContext'

export default function CallToActionSection() {
  const { userProfile } = useUser()

  return (
    <section className="relative py-0 sm:py-24 px-8 sm:px-16 lg:px-16 overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1 }}
        className="absolute inset-0 opacity-20 px-16"
      />
      
      <div className="container mx-auto text-center relative z-10 py-16 px-8 rounded-3xl overflow-hidden mb-8"
      style={
        {
          backgroundImage: "url('/hero-bg.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }
      }>
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-2xl sm:text-4xl font-heading tracking-tighter text-white mb-4"
        >
          Begin Your Cosmic Journey
        </motion.h2>
        
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="text-sm sm:text-xl text-white/80 mb-8 max-w-2xl mx-auto"
        >
          Discover the wisdom of the stars and cards. Your personalized spiritual guidance awaits.
        </motion.p>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
          className="flex flex-col sm:flex-row gap-4 justify-center"
        >
          <Button 
            asChild 
            size="lg" 
            className="bg-white/90 hover:bg-white text-primary rounded-xl"
          >
            <Link href={userProfile ? `/${userProfile.handle}/profile` : "/signup"}>
              <div className="flex items-center justify-center gap-2">
                {userProfile ? 'View Your Readings' : 'Get Your First Reading'}
              </div>
            </Link>
          </Button>
        </motion.div>
      </div>

      {/* Decorative Elements */}
      <motion.div
        animate={{
          y: [0, -10, 0],
          rotate: [0, 5, -5, 0],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className="absolute top-1/4 left-10 text-white/30"
      >
        <Moon className="w-12 h-12" />
      </motion.div>

      <motion.div
        animate={{
          y: [0, 10, 0],
          rotate: [0, -5, 5, 0],
        }}
        transition={{
          duration: 7,
          repeat: Infinity,
          repeatType: "reverse",
        }}
        className="absolute bottom-1/4 right-10 text-white/30"
      >
        <Sparkles className="w-16 h-16" />
      </motion.div>

      {/* Updated Glowing Orb Effect to complement gradient */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.6, duration: 1, ease: "easeOut" }}
        className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/4 w-full h-64 bg-white/5 rounded-full blur-3xl"
      />
    </section>
  )
}
