import React from 'react';
import Link from 'next/link';

interface ButtonProps {
  onClick?: () => void;
  href?: string;
  variant?: 'primary' | 'secondary' | 'text' | 'danger'; // Add danger variant
  textVariant?: 'default' | 'small' | 'large';
  icon?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string; // Add className prop
  size?: string;
  asChild?: boolean; // Add this line
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  href,
  variant = 'primary',
  textVariant = 'default',
  icon,
  children,
  disabled = false,
  type = 'button',
  className = '', // Default to an empty string
  size,
}) => {
  const baseClasses = 'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 font-subheading';
  const variantClasses = variant === 'primary'
    ? 'text-primary bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
    : variant === 'secondary'
    ? 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500'
    : variant === 'danger'
    ? 'text-primary bg-red-600 hover:bg-red-700 focus:ring-red-500'
    : 'text-indigo-600 hover:text-indigo-500';
  const disabledClasses = 'opacity-50 cursor-not-allowed';

  // Define text size classes based on textVariant
  const textSizeClasses = textVariant === 'small' ? 'text-xs' : textVariant === 'large' ? 'text-lg' : 'text-sm';

  const sizeClasses = size === 'small' ? 'px-2 py-1' : size === 'large' ? 'px-6 py-3' : 'px-4 py-2'; // Add size logic

  const classes = `${baseClasses} ${variantClasses} ${textSizeClasses} ${sizeClasses} ${disabled ? disabledClasses : ''} ${className}`;

  if (href) {
    return (
      <Link href={href} className={classes} onClick={disabled ? (e) => e.preventDefault() : onClick}>
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </Link>
    );
  }

  return (
    <button type={type} className={classes} onClick={onClick} disabled={disabled}>
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </button>
  );
};

export default Button;
