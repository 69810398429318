'use client'

import { createContext, useContext, useEffect, useState, useRef } from 'react'
import { onAuthStateChanged, User, signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from '@/lib/firebase/firebase'
import { UserProfile } from '@/lib/types/index'
import { doc, onSnapshot, collection, getDocs, writeBatch } from 'firebase/firestore'
import { Timestamp } from 'firebase/firestore'
import { updateUserProfile, isHandleAvailable, uploadProfilePicture as uploadProfilePictureService, createUserProfile } from '@/services/userService'
import { Area } from 'react-easy-crop'
import { GoogleAuthProvider } from 'firebase/auth'
import { updateLastLogin, fetchUserProfile } from '@/services/userService'
import { SUBSCRIPTION_PLANS } from '@/config/subscriptionPlans'
import { getCroppedImg } from '@/lib/utils/cropImage'

interface UserContextType {
  user: User | null;
  userProfile: UserProfile | null;
  loading: boolean;
  error: Error | null;
  updateProfile: (updates: Partial<UserProfile>) => Promise<void>;
  createProfile: (handle: string) => Promise<void>;
  uploadProfilePicture: (file: File, croppedAreaPixels: Area) => Promise<void>;
  signOut: () => Promise<void>;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<boolean>;
  signUpWithEmail: (email: string, password: string) => Promise<string>;
  deleteUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null)
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const retryCount = useRef(0)
  const currentUid = useRef<string | null>(null)

  const updateProfile = async (updates: Partial<UserProfile>) => {
    if (!user?.uid || !userProfile) return;
    
    try {
      await updateUserProfile(user.uid, {
        ...updates,
        updatedAt: Timestamp.now()
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error instanceof Error ? error : new Error('Failed to update profile'));
      throw error;
    }
  };

  const createProfile = async (handle: string) => {
    if (!user) throw new Error('No authenticated user');
    if (!user.email) throw new Error('User email is required');
    
    try {
      const isAvailable = await isHandleAvailable(handle);
      if (!isAvailable) {
        throw new Error('Handle is already taken');
      }

      // Get name from Google account if available
      const displayName = user.displayName || '';
      const [firstName = '', lastName = ''] = displayName.split(' ');

      const newProfile: Omit<UserProfile, 'lastLoginAt' | 'updatedAt'> = {
        uid: user.uid,
        email: user.email,
        handle: handle,
        firstName: firstName,
        lastName: lastName,
        profilePictureUrl: '',
        googlePhotoUrl: user.photoURL || '',
        createdAt: Timestamp.now(),
        subscription: {
          subscriptionId: null,
          customerId: null,
          priceId: SUBSCRIPTION_PLANS.FREE.id,
          status: 'active',
          updatedAt: Timestamp.now(),
          plan: 'FREE',
          trialEnd: Timestamp.fromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000))
        },
        tokenAllowance: {
          subscriptionTokens: {
            plan: 'FREE',
            status: 'active',
            currentPeriodEnd: null,
            total: SUBSCRIPTION_PLANS.FREE.tokenAllowance,
            remaining: SUBSCRIPTION_PLANS.FREE.tokenAllowance,
            lastUpdated: Timestamp.now()
          },
          nonExpiringTokens: {
            total: 0,
            remaining: 0,
            lastUpdated: Timestamp.now()
          }
        }
      };

      await createUserProfile(user.uid, newProfile);
    } catch (error) {
      console.error('Error creating profile:', error);
      setError(error instanceof Error ? error : new Error('Failed to create profile'));
      throw error;
    }
  };

  const uploadProfilePicture = async (file: File, croppedAreaPixels: Area) => {
    if (!user?.uid) return;

    try {
      // 1. Create object URL from file
      const objectUrl = URL.createObjectURL(file);
      
      // 2. Convert Area to Blob using getCroppedImg
      const croppedBlob = await getCroppedImg(objectUrl, croppedAreaPixels);
      URL.revokeObjectURL(objectUrl);  // Clean up

      if (!croppedBlob) {
        throw new Error('Failed to crop image');
      }

      // 3. Now pass the Blob to the service
      const downloadURL = await uploadProfilePictureService(user.uid, file, croppedBlob);
      await updateProfile({ profilePictureUrl: downloadURL });
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      setError(error instanceof Error ? error : new Error('Failed to upload profile picture'));
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
      setUserProfile(null);
      setError(null);
    } catch (error) {
      console.error('Error signing out:', error);
      setError(error instanceof Error ? error : new Error('Failed to sign out'));
      throw error;
    }
  };

  const signInWithEmail = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await updateLastLogin(userCredential.user.uid);
    } catch (error) {
      console.error('Error signing in:', error);
      setError(error instanceof Error ? error : new Error('Failed to sign in'));
      throw error;
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const profile = await fetchUserProfile(result.user.uid);
      
      if (!profile) {
        // New user - will be redirected to handle selection
        return false;
      }
      
      await updateLastLogin(result.user.uid);
      return true;
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError(error instanceof Error ? error : new Error('Failed to sign in with Google'));
      throw error;
    }
  };

  const signUpWithEmail = async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      return userCredential.user.uid;
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error instanceof Error ? error : new Error('Failed to sign up'));
      throw error;
    }
  };

  const deleteUser = async () => {
    if (!user?.uid) throw new Error('No authenticated user');
    
    try {
      // 1. Delete user data from Firestore
      const batch = writeBatch(db);
      
      // Delete user profile
      const profileRef = doc(db, 'userProfiles', user.uid);
      batch.delete(profileRef);
      
      // Delete charts
      const chartsRef = collection(db, 'userProfiles', user.uid, 'charts');
      const chartDocs = await getDocs(chartsRef);
      chartDocs.forEach(doc => batch.delete(doc.ref));
      
      // Delete readings
      const readingsRef = collection(db, 'userProfiles', user.uid, 'readings');
      const readingDocs = await getDocs(readingsRef);
      readingDocs.forEach(doc => batch.delete(doc.ref));
      
      await batch.commit();

      // 2. Delete user from Authentication
      await user.delete();
      
      // 3. Clear local state
      setUser(null);
      setUserProfile(null);
      setError(null);
      
    } catch (error) {
      console.error('Error deleting user:', error);
      setError(error instanceof Error ? error : new Error('Failed to delete user'));
      throw error;
    }
  };

  useEffect(() => {
    let unsubscribeProfile: (() => void) | undefined;
    let isSubscribed = true;

    const setupProfileListener = (uid: string) => {
      if (currentUid.current === uid) return;
      currentUid.current = uid;

      console.log('Setting up profile listener for uid:', uid);
      
      if (unsubscribeProfile) {
        unsubscribeProfile();
      }

      unsubscribeProfile = onSnapshot(
        doc(db, 'userProfiles', uid),
        (doc) => {
          if (!isSubscribed) return;
          console.log('Profile snapshot received:', { exists: doc.exists() });

          if (doc.exists()) {
            const profile = doc.data() as UserProfile;
            setUserProfile({
              ...profile,
              displayPictureUrl: profile.profilePictureUrl || profile.googlePhotoUrl || '/placeholder.jpg'
            });
            setError(null);
            retryCount.current = 0;
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error fetching profile:', error);
          setError(error);
          setLoading(false);
        }
      );
    };

    const unsubscribeAuth = onAuthStateChanged(auth, (firebaseUser) => {
      if (!isSubscribed) return;
      
      console.log('Auth state changed:', { 
        isLoggedIn: !!firebaseUser,
        userId: firebaseUser?.uid,
        loading,
        currentUid: currentUid.current
      });

      setUser(firebaseUser);
      
      if (!firebaseUser) {
        if (unsubscribeProfile) {
          unsubscribeProfile();
        }
        currentUid.current = null;
        setUserProfile(null);
        setLoading(false);
        return;
      }

      setupProfileListener(firebaseUser.uid);
    });

    return () => {
      isSubscribed = false;
      if (unsubscribeProfile) {
        unsubscribeProfile();
      }
      unsubscribeAuth();
      currentUid.current = null;
    };
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      console.log('🔥 Token Changed:', { 
        hasUser: !!user, 
        uid: user?.uid 
      })
      
      if (user) {
        const token = await user.getIdToken()
        console.log('🎟️ Setting token in cookie/storage')
        document.cookie = `firebaseToken=${token}; path=/; max-age=3600; SameSite=Strict`
        sessionStorage.setItem('firebaseToken', token)
      } else {
        console.log('🧹 Clearing auth state')
        document.cookie = 'firebaseToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT'
        sessionStorage.removeItem('firebaseToken')
      }
    })

    return () => unsubscribe()
  }, [])

  return (
    <UserContext.Provider value={{ 
      user, 
      userProfile, 
      loading, 
      error,
      updateProfile,
      createProfile,
      uploadProfilePicture,
      signOut,
      signInWithEmail,
      signInWithGoogle,
      signUpWithEmail,
      deleteUser,
    }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
