'use client'

import React from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { Stars } from 'lucide-react'
import Image from 'next/image'
const footerLinks = {
  product: [
    { name: 'Features', href: '/#features' },
    { name: 'Benefits', href: '/#benefits' },
    { name: 'Pricing', href: '/#pricing' },
    { name: 'FAQ', href: '/#faq' },
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Service', href: '/tos' },
  ],
  company: [
    { name: 'About Us', href: '/about' },
    { name: 'Blog', href: '/blog' },
    { name: 'Contact', href: '/contact' },
  ]
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.8 },
  },
}

export default function FooterSection() {
  return (
    <footer className="relative py-8 px-8 sm:px-6 lg:px-8 overflow-hidden">
      <div className="absolute inset-0 bg-gray-800"/>
      
      <div className="container mx-auto relative z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-2 md:grid-cols-12 gap-8 pb-8 border-b border-primary/10"
        >
          {/* Brand Column */}
          <motion.div variants={itemVariants} className="col-span-2 md:col-span-4">
            <Link href="/" className="flex items-center gap-2 !text-blue-50">
              <Stars className="h-6 w-6" />
              <span className="text-sm sm:text-xl font-heading !text-blue-50">Arcana Vibes</span>
            </Link>
            <p className="mt-4 !text-blue-50 text-xs sm:text-sm leading-tight">
              Discover your cosmic journey through personalized tarot readings and astrological insights.
            </p>
            <div className="flex gap-4 mt-6">
              <Link href="https://x.com/arcanavibes" target="_blank" className="!text-blue-50 hover:!text-blue-50">
                <Image src="/social/x.png" alt="X" className="h-16 w-16 sm:h-6 sm:w-6" width={66} height={66} />
              </Link>
              <Link href="https://www.instagram.com/arcanavibestarot/" target="_blank" className="!text-blue-50 hover:!text-blue-50">
                <Image src="/social/ig.png" alt="Instagram" className="h-16 w-16 sm:h-6 sm:w-6" width={66} height={66} />
              </Link>
              <Link href="https://www.tiktok.com/@arcanavibestarot" target="_blank" className="!text-blue-50 hover:!text-blue-50">
                <Image src="/social/tiktok.png" alt="TikTok" className="h-16 w-16 sm:h-6 sm:w-6" width={66} height={66} />
              </Link>
            </div>
          </motion.div>

          {/* Links Columns */}
          <motion.div variants={itemVariants} className="md:col-span-2">
            <h3 className="text-xs sm:text-sm font-normal !text-blue-50 mb-4">Product</h3>
            <ul className="space-y-3">
              {footerLinks.product.map((link) => (
                <li key={link.name}>
                  <Link href={link.href} className="text-xs sm:text-sm !text-blue-50 hover:!text-blue-50">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </motion.div>

          {/* <motion.div variants={itemVariants} className="md:col-span-2">
            <h3 className="text-sm font-normal !text-blue-50 mb-4">Company</h3>
            <ul className="space-y-3">
              {footerLinks.company.map((link) => (
                <li key={link.name}>
                  <Link href={link.href} className="text-sm !text-blue-50 hover:!text-blue-50">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </motion.div> */}

          <motion.div variants={itemVariants} className="md:col-span-2">
            <h3 className="text-xs sm:text-sm font-normal !text-blue-50 mb-4">Legal</h3>
            <ul className="space-y-3">
              {footerLinks.legal.map((link) => (
                <li key={link.name}>
                  <Link href={link.href} className="text-xs sm:text-sm !text-blue-50 hover:!text-blue-50">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </motion.div>
        </motion.div>

        {/* Copyright */}
        <motion.div
          variants={itemVariants}
          className="pt-4 sm:pt-8 text-center text-xs sm:text-sm !text-blue-50"
        >
          <p>&copy; {new Date().getFullYear()} Arcana Vibes. All rights reserved.</p>
        </motion.div>
      </div>
    </footer>
  )
} 