import React, { createContext, useContext, useState, useCallback } from 'react';
import { useBreakpoint } from '@/hooks/useBreakpoint';

export type View = 
          | 'spread' 
          | 'details' 
          | 'chat' 
          | 'natal-chart' 
          | 'astrology'
          | 'daily-draw' 
          | 'past-readings'
          | 'natal-chart-core'
          | 'natal-chart-elements'
          | 'natal-chart-aspects'
          | 'natal-chart-angles'
          | 'natal-chart-houses'
          | 'natal-chart-planets'
          | 'profile-settings'; 

interface LayoutContextType {
  currentView: View;
  setView: (view: View) => void;
  isMobile: boolean;
  isDesktop: boolean;
}


const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export function LayoutProvider({ children }: { children: React.ReactNode }) {
  const [currentView, setCurrentView] = useState<View>('daily-draw' as View);
  const { isMobile, isDesktop } = useBreakpoint();

  const setView = useCallback((view: View) => {
    setCurrentView(view);
  }, []);

  return (
    <LayoutContext.Provider value={{ currentView, setView, isMobile, isDesktop }}>
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayout() {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
} 