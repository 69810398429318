import { doc, getDoc, onSnapshot, collection, query, where, getDocs, updateDoc, deleteDoc, setDoc, deleteField, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '@/lib/firebase/firebase';
import { UserProfile, SubscriptionStatus, TokenAllowance } from '@/lib/types/index';
import { Timestamp } from 'firebase/firestore';
import { SUBSCRIPTION_PLANS } from '@/config/subscriptionPlans';

export async function fetchUserProfile(uid: string): Promise<UserProfile | null> {
  try {
    const docRef = doc(db, 'userProfiles', uid);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const profile = docSnap.data() as UserProfile;
      
      // Initialize defaults if needed
      if (!profile.subscription) {
        const sevenDaysFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
        sevenDaysFromNow.setUTCHours(0, 0, 0, 0);

        profile.subscription = {
          subscriptionId: null,
          customerId: null,
          priceId: SUBSCRIPTION_PLANS.FREE.id,
          status: 'active' as SubscriptionStatus,
          updatedAt: Timestamp.now(),
          plan: 'FREE',
          trialEnd: Timestamp.fromDate(sevenDaysFromNow)
        };
        
        // Update the document with defaults
        await updateDoc(docRef, {
          subscription: profile.subscription
        });
      }
      
      return profile;
    }
    return null;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
}

export async function updateUserProfile(uid: string, updatedProfile: Partial<UserProfile>): Promise<void> {
  try {
    const userRef = doc(db, 'userProfiles', uid);
    await updateDoc(userRef, {
      ...updatedProfile,
      updatedAt: Timestamp.now() // Add a timestamp for when the profile was last updated
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
}

export async function createUserProfile(
  uid: string,
  data: Partial<UserProfile>
): Promise<void> {
  const sevenDaysFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
  
  // Set to midnight UTC
  sevenDaysFromNow.setUTCHours(0, 0, 0, 0);

  const userProfileRef = doc(db, 'userProfiles', uid);
  await setDoc(userProfileRef, {
    ...data,
    uid,
    createdAt: serverTimestamp(),
    subscription: {
      plan: 'FREE',
      status: 'active',
      priceId: 'price_free',
      trialEnd: Timestamp.fromDate(sevenDaysFromNow),
    },
    tokenAllowance: {
      subscriptionTokens: {
        remaining: 5000000,
        total: 5000000
      }
    }
  });
}

export async function deleteUserProfile(uid: string): Promise<void> {
  try {
    const docRef = doc(db, 'userProfiles', uid);
    await deleteDoc(docRef);
  } catch (error) {
    console.error('Error deleting user profile:', error);
    throw error;
  }
}

export async function updateLastLogin(uid: string): Promise<void> {
  try {
    const docRef = doc(db, 'userProfiles', uid);
    await updateDoc(docRef, {
      lastLoginAt: Timestamp.now(),
    });
  } catch (error) {
    console.error('Error updating last login:', error);
    throw error;
  }
}

export async function findUserByHandle(handle: string): Promise<UserProfile | null> {
  try {
    const q = query(collection(db, 'userProfiles'), where('handle', '==', handle));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return doc.data() as UserProfile;
    }
    return null;
  } catch (error) {
    console.error('Error finding user by handle:', error);
    throw error;
  }
}

export async function isHandleAvailable(handle: string): Promise<boolean> {
  try {
    const user = await findUserByHandle(handle);
    return user === null;
  } catch (error) {
    console.error('Error checking handle availability:', error);
    throw error;
  }
}

export async function uploadProfilePicture(uid: string, file: File, imageBlob: Blob): Promise<string> {
  try {
    const storageRef = ref(storage, `profilePictures/${uid}/${file.name}`);
    await uploadBytes(storageRef, imageBlob);
    const downloadURL = await getDownloadURL(storageRef);
    
    const userRef = doc(db, 'userProfiles', uid);
    await updateDoc(userRef, {
      profilePictureUrl: downloadURL,
      googlePhotoUrl: deleteField() // Remove the Google photo URL if it exists
    });

    return downloadURL;
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    throw error;
  }
}

export function subscribeToProfileUpdates(
  uid: string, 
  callback: (profile: UserProfile | null) => void,
  onError?: (error: Error) => void
): () => void {
  const userRef = doc(db, 'userProfiles', uid);
  
  return onSnapshot(
    userRef,
    (docSnapshot) => {
      if (docSnapshot.exists()) {
        callback(docSnapshot.data() as UserProfile);
      } else {
        callback(null);
      }
    },
    (error) => {
      console.error('Error in profile subscription:', error);
      if (onError) {
        onError(error);
      }
      callback(null);
    }
  );
}

export interface UpdateSubscriptionParams {
  subscriptionId: string;
  customerId: string;
  priceId: string;
  status?: 'active' | 'paused' | 'canceled';
}

export async function updateUserSubscription(uid: string, params: UpdateSubscriptionParams): Promise<void> {
  try {
    console.log('Updating user subscription:', { uid, ...params });
    const userRef = doc(db, 'userProfiles', uid);
    
    // Determine the subscription plan based on the priceId
    let subscriptionPlan: 'FREE' | 'PRO' = 'FREE';
     if (params.priceId === process.env.NEXT_PUBLIC_STRIPE_PRO_PRICE_ID) {
      subscriptionPlan = 'PRO';
    }

    const now = Timestamp.now();
    const subscription = {
      subscriptionId: params.subscriptionId,
      customerId: params.customerId,
      priceId: params.priceId,
      status: params.status || 'active',
      updatedAt: now,
      plan: subscriptionPlan
    };

    // Get the token allowance for the new plan
    const planTokens = SUBSCRIPTION_PLANS[subscriptionPlan].tokenAllowance;

    const updates = {
      subscription,
      'tokenAllowance.subscriptionTokens': {
        plan: subscriptionPlan,
        status: subscription.status,
        currentPeriodEnd: null,
        total: planTokens,
        remaining: planTokens,
        lastUpdated: now
      },
      updatedAt: now
    };

    await updateDoc(userRef, updates);

    console.log('User subscription updated successfully with token allowance:', updates);
  } catch (error) {
    console.error('Error updating user subscription:', error);
    throw error;
  }
}

export async function forceUpdateSubscription(uid: string): Promise<void> {
  try {
    console.log('Force updating user subscription data');
    const userRef = doc(db, 'userProfiles', uid);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      throw new Error('User profile not found');
    }

    const userData = userDoc.data() as UserProfile;
    const now = Timestamp.now();
    
    // Get current subscription data
    const subscription = userData.subscription;
    if (!subscription) {
      // If no subscription, update with FREE plan defaults
      await updateDoc(userRef, {
        subscription: {
          subscriptionId: null,
          customerId: null,
          priceId: SUBSCRIPTION_PLANS.FREE.id,
          status: 'active' as const,
          updatedAt: now,
          plan: 'FREE' as const
        },
        tokenAllowance: {
          subscriptionTokens: {
            plan: 'FREE',
            status: 'active',
            currentPeriodEnd: null,
            total: SUBSCRIPTION_PLANS.FREE.tokenAllowance,
            remaining: SUBSCRIPTION_PLANS.FREE.tokenAllowance,
            lastUpdated: now
          }
        },
        updatedAt: now
      });
      return;
    }

    // Now we know subscription exists
    const subscriptionPlan = subscription.plan;
    const planTokens = SUBSCRIPTION_PLANS[subscriptionPlan].tokenAllowance;

    // Create update object
    const updates = {
      // Remove legacy field
      subscriptionPlan: deleteField(),
      
      // Update subscription to ensure all fields are correct
      subscription: {
        ...subscription,
        status: 'active',
        updatedAt: now
      },
      
      // Update token allowance
      'tokenAllowance.subscriptionTokens': {
        plan: subscriptionPlan,
        status: 'active',
        currentPeriodEnd: null,
        total: planTokens,
        remaining: planTokens,
        lastUpdated: now
      },
      
      updatedAt: now
    };

    await updateDoc(userRef, updates);
    console.log('Force update completed successfully:', updates);
  } catch (error) {
    console.error('Error force updating subscription:', error);
    throw error;
  }
}

export async function updateNatalChart(uid: string, chartData: any): Promise<void> {
  try {
    const userRef = doc(db, 'userProfiles', uid);
    await updateDoc(userRef, {
      'birthInfo.natalChart': chartData
    });
  } catch (error) {
    console.error('Error updating natal chart:', error);
    throw error;
  }
}
