import { collection, doc, getDocs, getDoc, query, where, limit } from 'firebase/firestore';
import { db } from '@/lib/firebase/firebase';
import type { ChartData, DerivedData, TransitData } from '@/lib/types/astrology';
import { Timestamp } from 'firebase/firestore';

export type Result<T> = {
  data: T | null;
  status: 'pending' | 'in_progress' | 'completed' | 'failed';
  error?: string;
};

export type TransitResult = Result<TransitData>;
export type DerivedResult = Result<DerivedData>;
export type ChartResult = Result<ChartData>;

// Firestore types that match the exact shape of the data in Firestore
interface FirestoreChartData {
  id?: string;
  type: ChartData['type'];
  date: Timestamp;
  location: ChartData['location'];
  settings: ChartData['settings'];
  planets: ChartData['planets'];
  houses: ChartData['houses'];
  angles: ChartData['angles'];
  fixedStars?: ChartData['fixedStars'];
  arabicParts?: ChartData['arabicParts'];
  derived?: {
    aspects: DerivedData['aspects'];
    patterns: DerivedData['patterns'];
    elementalBalance: DerivedData['elementalBalance'];
    dispositorChains: DerivedData['dispositorChains'];
    status: DerivedData['status'];
    lastUpdated: Timestamp;
  };
  metadata?: ChartData['metadata'];
}

export async function fetchNatalChart(userId: string): Promise<ChartResult> {
  try {
    const chartsRef = collection(db, 'userProfiles', userId, 'charts');
    const chartSnapshot = await getDocs(query(
      chartsRef,
      where('metadata.isPrimary', '==', true),
      limit(1)
    ));

    if (chartSnapshot.empty) {
      return {
        data: null,
        status: 'pending',
        error: 'No natal chart found'
      };
    }

    const chartDoc = chartSnapshot.docs[0];
    const firestoreData = chartDoc.data();

    // Transform Firestore data to ChartData
    const chartData: ChartData = {
      id: chartDoc.id,
      type: 'Natal',
      date: firestoreData.date.toDate(),
      location: firestoreData.location,
      planets: firestoreData.planets,
      houses: firestoreData.houses,
      angles: firestoreData.angles,
      aspects: firestoreData.aspects || [],
      settings: firestoreData.settings || {},
      derived: firestoreData.derived && {
        lastUpdated: firestoreData.derived.lastUpdated.toDate(),
        aspects: firestoreData.derived.aspects,
        patterns: firestoreData.derived.patterns,
        elementalBalance: firestoreData.derived.elementalBalance,
        dispositorChains: firestoreData.derived.dispositorChains,
        status: firestoreData.derived.status
      },
      metadata: firestoreData.metadata
    };

    return {
      data: chartData,
      status: 'completed'
    };
  } catch (error) {
    console.error('Error fetching natal chart:', error);
    return {
      data: null,
      status: 'failed',
      error: error instanceof Error ? error.message : 'Failed to fetch natal chart'
    };
  }
}

export async function fetchTransitData(userId: string, chartId: string): Promise<TransitResult> {
  try {
    const today = new Date().toISOString().split('T')[0];
    const transitRef = doc(db, 'userProfiles', userId, 'charts', chartId, 'transit', today);
    const transitDoc = await getDoc(transitRef);

    if (!transitDoc.exists()) {
      return {
        data: null,
        status: 'pending',
        error: 'Transit data not yet calculated'
      };
    }

    const firestoreData = transitDoc.data();
    
    if (!firestoreData || !firestoreData.movements || !Array.isArray(firestoreData.movements)) {
      console.error('Invalid transit data structure:', firestoreData);
      return {
        data: null,
        status: 'failed',
        error: 'Invalid transit data structure'
      };
    }

    const transitData: TransitData = {
      natalChartId: chartId,
      transitChart: firestoreData.transitChart,
      movements: firestoreData.movements.map((m: any) => ({
        ...m,
        entryDate: m.entryDate?.toDate() || new Date(),
        exitDate: m.exitDate?.toDate() || new Date()
      })),
      timestamp: firestoreData.timestamp.toDate(),
      timezone: firestoreData.timezone,
      lastUpdated: firestoreData.lastRefreshed?.toDate() || new Date()
    };

    return {
      data: transitData,
      status: 'completed'
    };
  } catch (error) {
    console.error('Error fetching transit data:', error);
    return {
      data: null,
      status: 'failed',
      error: error instanceof Error ? error.message : 'Failed to fetch transit data'
    };
  }
}

export async function fetchDerivedData(userId: string, chartId: string): Promise<{
  data: DerivedData | null;
  status: 'pending' | 'in_progress' | 'completed' | 'failed';
  error?: string;
}> {
  try {
    const derivedRef = doc(db, 'userProfiles', userId, 'charts', chartId, 'derived', 'data');
    const derivedDoc = await getDoc(derivedRef);
    
    if (!derivedDoc.exists()) {
      return {
        data: null,
        status: 'pending',
        error: 'No derived data found'
      };
    }
    
    const firestoreData = derivedDoc.data() as FirestoreChartData['derived'];
    
    if (!firestoreData) {
      return {
        data: null,
        status: 'pending',
        error: 'Derived data is missing'
      };
    }

    // Ensure all required fields are present
    if (!firestoreData.aspects || !firestoreData.patterns || 
        !firestoreData.elementalBalance || !firestoreData.dispositorChains) {
      return {
        data: null,
        status: 'pending',
        error: 'Derived data is incomplete'
      };
    }

    // Convert Firestore data to app data
    const derivedData: DerivedData = {
      aspects: firestoreData.aspects,
      patterns: firestoreData.patterns,
      elementalBalance: firestoreData.elementalBalance,
      dispositorChains: firestoreData.dispositorChains,
      status: firestoreData.status || 'pending',
      lastUpdated: firestoreData.lastUpdated?.toDate() || new Date()
    };

    return {
      data: derivedData,
      status: firestoreData.status || 'pending'
    };
  } catch (error) {
    console.error('Error fetching derived data:', error);
    return {
      data: null,
      status: 'failed',
      error: error instanceof Error ? error.message : 'Unknown error'
    };
  }
} 