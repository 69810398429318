'use client'

import React, { useState } from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { Check, Sparkle } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { SUBSCRIPTION_PLANS } from '@/config/subscriptionPlans'
import { cn } from '@/lib/utils'
import { useUser } from '@/contexts/UserContext'

export default function PricingSection() {
  const [isYearly, setIsYearly] = useState(false)
  const { userProfile } = useUser()
  return (
    <section className="pt-16 pb-16 sm:pt-24 sm:pb-0 px-8 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-xl sm:text-3xl font-normal font-heading tracking-tighter text-center mb-2 sm:mb-4"
        >
          Choose Your Journey
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.1 }}
          className="text-sm sm:text-lg text-center sm:mb-16 mb-8"
        >
          Unlock deeper insights and and more personalized guidance
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8 max-w-6xl mx-auto">
          {Object.entries(SUBSCRIPTION_PLANS).map(([key, plan], index) => (
            <motion.div
              key={key}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.8 }}
              className={cn(
                "bg-card p-8 rounded-3xl shadow-lg relative",
                plan.id !== 'price_free' && "border-2 border-primary"
              )}
            >
              {plan.id !== 'price_free' && (
                <div className="absolute top-0 right-0 bg-primary text-primary-foreground py-1 px-3 rounded-bl-lg rounded-tr-2xl flex items-center">
                  <Sparkle className="w-4 h-4 mr-1" /> Premium
                </div>
              )}
              <h3 className="text-sm sm:text-xl font-normal text-foreground sm:mb-2 mb-1">{plan.name}</h3>
                <p className="text-xl sm:text-3xl font-bold text-primary sm:mb-4 mb-2">
                ${plan.price}
                <span className="text-xs sm:text-base font-normal text-muted-foreground">/month</span>
              </p>
              <ul className="text-muted-foreground mb-6 space-y-3">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-start gap-2 text-xs sm:text-base">
                    <Check className={cn(
                      "w-4 h-4 sm:w-5 sm:h-5 mt-0.5 shrink-0",
                      feature.included ? "text-primary" : "text-primary/30"
                    )} />
                    <span className={cn(
                      feature.included ? "text-primary" : "text-primary/30"
                    )}>{feature.name}</span>
                  </li>
                ))}
              </ul>
              <Button 
                asChild
                className={cn(
                  "w-full rounded-lg",
                  plan.id !== 'price_free' && "bg-primary text-primary-foreground hover:bg-primary/90"
                )}
                size="lg"
              >
                <Link href={userProfile ? "/upgrade" : "/signup"}>
                  {plan.id === 'price_free' ? 'Get Started' : 'Upgrade Now'}
                </Link>
              </Button>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}