'use client'

import React from 'react'
import { motion } from 'framer-motion'
import Image from 'next/image'

const benefits = [
  {
    image: '/benefits/1.png',
    title: 'Personalized Readings',
    description: "Every reading is uniquely crafted using your birth chart and current transits—no more generic interpretations that could apply to anyone."
  },
  {
    image: '/benefits/2.png',
    title: 'Cosmic Timing',
    description: "Understand how planetary movements affect your readings. Get insights aligned with your personal transits and optimal timing for decisions."
  },
  {
    image: '/benefits/3.png',
    title: 'Chart Integration',
    description: "Your natal placements and current transits add depth to every reading, revealing how the cards' messages relate to your unique cosmic blueprint."
  },
  {
    image: '/benefits/4.png',
    title: 'Pattern Recognition',
      description: "Track how readings evolve with your transits. Discover recurring themes and gain deeper understanding of your personal cycles."
  }
]

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
}

export default function BenefitsSection() {
  return (
    <section className="pt-16 pb-16 sm:pt-24 sm:pb-0 px-8 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-xl sm:text-3xl font-normal font-heading tracking-tighter text-center sm:mb-4 mb-2"
        >
          No More Generic Readings.
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.1 }}
          className="text-sm sm:text-lg text-center sm:mb-16 mb-8 text-primary/70 max-w-2xl mx-auto"
        >
          Arcana Vibes combines the wisdom of tarot with your personal astrology, creating a uniquely insightful experience that evolves with you.
        </motion.p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="flex flex-col items-center text-center"
            >
              <div className="rounded-full mb-0 sm:mb-6">
                <Image src={benefit.image} alt={benefit.title} width={200} height={200} className="drop-shadow-md" />
              </div>
              <h3 className="text-sm sm:text-xl font-normal mb-4">{benefit.title}</h3>
              <p className="text-primary/70 text-xs sm:text-base leading-tight sm:leading-snug">{benefit.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}