"use client";

import React, { useState, useRef, useEffect } from "react";
import Link from "next/link";
import { useRouter, usePathname } from "next/navigation";
import { Zap, LogOut, CreditCard, UserCircle, Coins } from "lucide-react";
import Image from "next/image";
import { useUser } from "@/contexts/UserContext";
import TokenDisplay from "./tokens/TokenDisplay";
import ProductHuntBadge from "./home/ProductHuntBadge";
import { useBreakpoint } from "@/hooks/useBreakpoint";
import { motion } from "framer-motion";

const Navbar = () => {
  const { user, userProfile, signOut } = useUser();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const pathname = usePathname();

  const isReadingPage = pathname?.includes("/readings/");
  const isHomePage = pathname === "/";

  const [isScrolled, setIsScrolled] = useState(false);

  const { isMobile } = useBreakpoint();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > window.innerHeight);
    };

    if (isHomePage) {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isHomePage]);

  const handleSignOut = async () => {
    try {
      await signOut();
      setIsDropdownOpen(false);
      router.push("/");
    } catch (error) {
      console.error("Error signing out:", error);
      // Error is handled by context
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Close dropdown when route changes
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [pathname]);

  const textColorClass = isMobile
    ? "text-primary"
    : isHomePage && !isScrolled
    ? "text-white"
    : "text-primary";

  const bgColorClass = isMobile
  ? "bg-white/70"
  : isHomePage && !isScrolled
  ? "bg-primary/70"
  : "bg-white/70";  

  const hoverBgColorClass = isMobile
  ? "hover:bg-white/80"
  : isHomePage && !isScrolled
  ? "hover:bg-primary/80"
  : "hover:bg-white/80";

  return (
    <nav
      className={`
      sm:bg-transparent fixed top-0 left-0 right-0 z-50 animate-in fade-in slide-in-from-top-5
      ${
        isHomePage
          ? "bg-transparent backdrop-blur-xl"
          : "bg-transparent backdrop-blur-xl"
      }
    `}
    >
      <div className="mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="flex space-x-7">
            <div className="flex items-center">
              <Link
                href="/"
                className="flex items-center py-4 px-2"
                prefetch={false}
              >
                <motion.div
                  whileHover={{ rotate: 360 }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                >
                  <Image
                    src="/AV.png"
                    alt="Arcana Vibes"
                    width={36}
                    height={36}
                    className="drop-shadow-xl"
                  />
                </motion.div>
                <span
                  className={` ${textColorClass} font-heading tracking-tight text-sm sm:text-md`}
                >
                  Arcana Vibes
                </span>
              </Link>
            </div>
          </div>
          <div className="flex items-center sm:space-x-3">
            <div className="hidden sm:block pt-1">
              <ProductHuntBadge />
            </div>
            {user && userProfile ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center sm:space-x-2 focus:outline-none pr-0 sm:pr-2"
                >
                  <span
                    className={`${textColorClass} text-sm sm:text-md hidden sm:block`}
                  >
                    Welcome, {userProfile.firstName}!
                  </span>
                  {userProfile.subscription?.plan === "PRO" ? (
                    ""
                  ) : (
                    <Link href="/upgrade">
                      <span className="ml-2 text-xs bg-gradient-to-r from-purple-500 to-pink-500 text-white px-2 py-0.5 rounded-full font-semibold mr-3 hover:from-purple-600 hover:to-pink-600 cursor-pointer">
                        SUBSCRIBE
                      </span>
                    </Link>
                  )}
                  <Image
                    className="h-8 w-8 rounded-full"
                    src={
                      userProfile.displayPictureUrl ||
                      "/placeholder.jpg?height=32&width=32"
                    }
                    alt={`${userProfile.firstName} ${userProfile.lastName}`}
                    width={32}
                    height={32}
                  />
                </button>
                {isDropdownOpen && (
                  <div
                    className={`absolute 
                    right-0 
                    mt-2 
                    w-48 
                    ${bgColorClass}
                    rounded-xl 
                    overflow-hidden 
                    shadow-xl 
                    z-10 
                    transform origin-top-right transition-all duration-200 ease-out
                    animate-in fade-in slide-in-from-top-5`}
                  >
                    <Link
                      href={`/${userProfile.handle}/profile`}
                      className={`block px-4 py-2 text-base ${textColorClass} ${hoverBgColorClass} transition-colors`}
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <UserCircle className="h-4 w-4 mr-2" />
                        Profile
                      </div>
                    </Link>

                    <Link
                      href={`/upgrade`}
                      className={`block px-4 py-2 text-base ${textColorClass} ${hoverBgColorClass} transition-colors `}
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <Zap className="h-4 w-4 mr-2" />
                        Subscribe
                      </div>
                    </Link>
                    <button
                      onClick={() => {
                        handleSignOut();
                      }}
                      className={`block w-full text-left px-4 py-2 text-base ${textColorClass} ${hoverBgColorClass} transition-colors`}
                    >
                      <div className="flex items-center font-subheading">
                        <LogOut className="h-4 w-4 mr-2" />
                        Sign Out
                      </div>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center space-x-2 focus:outline-none pr-0"
                >
                  <UserCircle className={`h-8 w-8 ${textColorClass}`} />
                </button>
                {isDropdownOpen && (
                  <div
                    className={`absolute 
                    right-0 
                    mt-2 
                    w-48 
                    ${bgColorClass}
                    rounded-xl 
                    overflow-hidden 
                    shadow-xl 
                    z-10 
                    transform origin-top-right transition-all duration-200 ease-out
                    animate-in fade-in slide-in-from-top-5`}
                  >
                    <Link
                      href="/signup"
                      className={`block px-4 py-2 text-base ${textColorClass} ${hoverBgColorClass} transition-colors`}
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <LogOut className="h-4 w-4 mr-2" />
                        Sign Up
                      </div>
                    </Link>
                    <Link
                      href="/signin"
                      className={`block px-4 py-2 text-base ${textColorClass} ${hoverBgColorClass} transition-colors`}
                      prefetch={false}
                    >
                      <div className="flex items-center font-subheading">
                        <LogOut className="h-4 w-4 mr-2" />
                        Sign In
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
