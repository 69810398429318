import type { Config } from "tailwindcss";
import animate from "tailwindcss-animate";
import typography from "@tailwindcss/typography";
import { fontFamily } from "tailwindcss/defaultTheme";

const config: Config = {
    darkMode: ["class"],
    content: [
        "./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
        "./src/components/**/*.{js,ts,jsx,tsx,mdx}",
        "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
    ],
    theme: {
        screens: {
            sm: "640px",
            md: "768px",
            lg: "1024px",
            xl: "1280px",
            "2xl": "1536px",
        },
        extend: {
            fontFamily: {
                body: ["var(--font-body)", ...fontFamily.sans],
                heading: ["var(--font-heading)", ...fontFamily.sans],
                subheading: ['var(--font-subheading)', 'sans-serif'],
                accent: ['var(--font-accent)', 'sans-serif'],
                display: ['var(--font-display)', 'serif'],
                symbol: ['var(--font-symbol)', 'sans-serif'],
                hebrew: ['var(--font-hebrew)', 'serif'],
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            typography: (theme: any) => ({
                DEFAULT: {
                    css: {
                        h1: {
                            fontFamily: theme("fontFamily.heading"),
                            fontWeight: 800,
                        },
                        h2: {
                            fontFamily: theme("fontFamily.subheading"),
                            fontWeight: "bold",
                        },
                        h3: {
                            fontFamily: theme("fontFamily.subheading"),
                            fontWeight: "bold",
                        },
                        p: {
                            fontFamily: 'var(--font-body)',
                            fontWeight: 300,
                            fontSize: theme("fontSize.base"),
                        },
                        '.prose': {
                            fontFamily: 'var(--font-body)',
                            fontWeight: 300,
                            '& p': {
                                fontFamily: 'var(--font-body)',
                                '& strong': {
                                    fontFamily: 'inherit',
                                },
                                '& em': {
                                    fontFamily: 'inherit',
                                }
                            },
                        },
                        label: {
                            fontFamily: theme("fontFamily.subheading"),
                            fontWeight: "normal",
                        },
                        dt: {
                            fontFamily: theme("fontFamily.subheading"),
                            fontWeight: "normal",
                        },
                    },
                },
            }),
            colors: {
                background: "hsl(var(--background))",
                foreground: "hsl(var(--foreground))",
                card: {
                    DEFAULT: "hsl(var(--card))",
                    foreground: "hsl(var(--card-foreground))",
                },
                popover: {
                    DEFAULT: "hsl(var(--popover))",
                    foreground: "hsl(var(--popover-foreground))",
                },
                primary: {
                    DEFAULT: "hsl(var(--primary))",
                    foreground: "hsl(var(--primary-foreground))",
                },
                secondary: {
                    DEFAULT: "hsl(var(--secondary))",
                    foreground: "hsl(var(--secondary-foreground))",
                },
                muted: {
                    DEFAULT: "hsl(var(--muted))",
                    foreground: "hsl(var(--muted-foreground))",
                },
                accent: {
                    DEFAULT: "hsl(var(--accent))",
                    foreground: "hsl(var(--accent-foreground))",
                },
                destructive: {
                    DEFAULT: "hsl(var(--destructive))",
                    foreground: "hsl(var(--destructive-foreground))",
                },
                border: "hsl(var(--border))",
                input: "hsl(var(--input))",
                ring: "hsl(var(--ring))",
                chart: {
                    1: "hsl(var(--chart-1))",
                    2: "hsl(var(--chart-2))",
                    3: "hsl(var(--chart-3))",
                    4: "hsl(var(--chart-4))",
                    5: "hsl(var(--chart-5))",
                },
            },
            borderRadius: {
                lg: "var(--radius)",
                md: "calc(var(--radius) - 2px)",
                sm: "calc(var(--radius) - 4px)",
            },
            fontSize: {
                base: "1rem",
                sm: "1.115rem",
                md: "1.15rem",
                lg: "1.25rem",
                xl: "1.75rem",
                "2xl": "2rem",
                "3xl": "3rem",
                "4xl": "4rem",
            },
            keyframes: {
                "fade-in": {
                    "0%": { opacity: "0" },
                    "100%": { opacity: "1" },
                },
                "slide-in-from-top": {
                    "0%": { transform: "translateY(-10px)" },
                    "100%": { transform: "translateY(0)" },
                },
                'mesh-fade': {
                    '0%, 100%': { opacity: "0.2", transform: 'scale(1.1)' },
                    '50%': { opacity: "0.7", transform: 'scale(1)' },
                },
                'mesh-slide': {
                    '0%': { transform: 'rotate(0deg) scale(1.2)' },
                    '50%': { transform: 'rotate(180deg) scale(1)' },
                    '100%': { transform: 'rotate(360deg) scale(1.2)' },
                },
                'blob-1': {
                    '0%, 100%': { transform: 'rotate(-45deg) translate(0, 0) scale(1)' },
                    '33%': { transform: 'rotate(-90deg) translate(10%, 10%) scale(1.1)' },
                    '66%': { transform: 'rotate(0deg) translate(-10%, 10%) scale(0.9)' },
                },
                'blob-2': {
                    '0%, 100%': { transform: 'rotate(45deg) translate(0, 0) scale(1)' },
                    '33%': { transform: 'rotate(90deg) translate(-10%, -10%) scale(0.9)' },
                    '66%': { transform: 'rotate(0deg) translate(10%, -10%) scale(1.1)' },
                },
                'blob-3': {
                    '0%, 100%': { transform: 'rotate(0deg) translate(0, 0) scale(1)' },
                    '33%': { transform: 'rotate(-45deg) translate(-10%, 10%) scale(1.1)' },
                    '66%': { transform: 'rotate(45deg) translate(10%, -10%) scale(0.9)' },
                },
            },
            animation: {
                "fade-in": "fade-in 0.3s ease-out",
                "slide-in-from-top": "slide-in-from-top 0.5s ease-out",
                'mesh-fade': 'mesh-fade 10s ease infinite',
                'mesh-slide': 'mesh-slide 15s ease infinite',
                'blob-1': 'blob-1 20s ease infinite',
                'blob-2': 'blob-2 25s ease infinite',
                'blob-3': 'blob-3 30s ease infinite',
            },
        },
    },
    plugins: [animate, typography],
};

export default config;