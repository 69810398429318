'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown } from 'lucide-react'

const faqs = [
  {
    question: "How accurate are the AI-powered readings?",
    answer: "Our readings combine traditional tarot wisdom with modern astrological data and AI interpretation. The system analyzes your birth chart, current transits, and card meanings to provide personalized insights tailored to your unique cosmic blueprint."
  },
  {
    question: "Do I need to know my birth time for the natal chart?",
    answer: "While an accurate birth time provides the most detailed insights, you can still receive readings without it. However, for precise house placements and advanced transit tracking, we recommend using your exact birth time."
  },
  {
    question: "What's included in the free trial?",
    answer: "The free trial gets full access for 7 days, including unlimited personalized interactive readings, daily draws, and astrological insights."
  },
  {
    question: "Can I save my readings for later reference?",
    answer: "Yes! All users can save their readings, with premium members having unlimited access to reading history and enhanced insights about patterns in their readings over time."
  },
  {
    question: "How often are the transit interpretations updated?",
    answer: "Transit interpretations are calculated in real-time based on current planetary positions. Your daily draws and insights are refreshed every 24 hours, taking into account the latest astrological influences."
  }
]

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
      delay: 0.5,
      duration: 0.8,
      ease: 'easeOut',
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
}

const FAQItem = ({ question, answer }: { question: string, answer: string }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border-b border-primary/10">
      <button
        className="flex justify-between items-center w-full py-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xs sm:text-lg font-normal text-primary">{question}</span>
        <ChevronDown
          className={`w-4 h-4 sm:w-5 sm:h-5 text-primary transition-transform duration-300 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <p className="py-4 text-primary/70 text-xs leading-tight sm:leading-snug sm:text-base">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default function FAQSection() {
  return (
    <section className="pt-16 pb-16 sm:pt-24 sm:pb-0 px-8 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <motion.h2
          variants={itemVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="text-xl sm:text-3xl font-normal font-heading tracking-tighter text-center mb-2 sm:mb-4"
        >
          Frequently Asked Questions
        </motion.h2>
        <motion.p
          variants={itemVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="text-sm sm:text-lg text-center sm:mb-16 mb-0 text-primary/70"
        >
          Everything you need to know about your cosmic journey
        </motion.p>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="max-w-3xl mx-auto bg-white/10 backdrop-blur-sm rounded-3xl p-8"
        >
          {faqs.map((faq, index) => (
            <motion.div key={index} variants={itemVariants}>
              <FAQItem question={faq.question} answer={faq.answer} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}