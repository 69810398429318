'use client'

import { createContext, useContext, useState } from 'react'
import { Card } from '@/lib/types/index'

export type LoadingState = 
  | 'INITIAL'
  | 'FETCHING_CHART'
  | 'WAITING_TRANSITS'
  | 'PROCESSING_TRANSITS'
  | 'COMPLETE'
  | 'ERROR';

interface DailyDrawContextType {
  currentCard: Card | null;
  setCurrentCard: (card: Card | null) => void;
  loadingState: LoadingState;
  setLoadingState: (state: LoadingState) => void;
}

const DailyDrawContext = createContext<DailyDrawContextType | undefined>(undefined)

export function DailyDrawProvider({ children }: { children: React.ReactNode }) {
  const [currentCard, setCurrentCard] = useState<Card | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>('INITIAL');

  return (
    <DailyDrawContext.Provider value={{ 
      currentCard, 
      setCurrentCard,
      loadingState,
      setLoadingState
    }}>
      {children}
    </DailyDrawContext.Provider>
  )
}

export function useDailyDraw() {
  const context = useContext(DailyDrawContext)
  if (context === undefined) {
    throw new Error('useDailyDraw must be used within a DailyDrawProvider')
  }
  return context
} 