'use client'

import React from 'react'
import { motion } from 'framer-motion'
import { Stars, Sparkle, ChartArea, Sparkles, Calendar, Share2 } from 'lucide-react'
import Image from 'next/image'
const features = [
  {
    icon: Stars,
    image: "/features/1.png",
    title: "Personal Birth Chart",
    description: "Get detailed insights into your natal chart with precise planetary positions and house placements."
  },
  {
    icon: Sparkle,
    image: "/features/2.png",
    title: "Daily Tarot Draws",
    description: "Receive personalized daily tarot readings aligned with your current astrological transits."
  },
  // {
  //   icon: ChartArea,
  //   title: "Transit Tracking",
  //   description: "Stay informed about current planetary movements and their influence on your natal chart."
  // },
  // {
  //   icon: Calendar,
  //   title: "Cosmic Timeline",
  //   description: "Track your personal astrological events and tarot journey with an interactive timeline."
  // },
  {
    icon: Sparkles,
    image: "/features/3.png",
    title: "Interactive Insights",
    description: "Experience deep interpretations combining astrology and tarot through advanced interactive analysis."
  },
  // {
  //   icon: Share2,
  //   title: "Share Your Journey",
  //   description: "Save and share your readings and insights with the Arcana community."
  // }
]

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 10,
      mass: 0.75,
    },
  },
}

const iconVariants = {
  hidden: { scale: 0.8, opacity: 0 },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 50,
      damping: 10,
      mass: 0.5,
    },
  },
}

export default function FeaturesSection() {
  return (
    <section className="pt-16 pb-16 sm:pt-24 sm:pb-0 px-8 sm:px-6 lg:px-8 overflow-hidden">
      <div className="container mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
          className="text-xl sm:text-3xl font-normal font-heading tracking-tighter text-foreground text-center mb-2 sm:mb-4"
        >
          Tarot and Astrology, personalized for you.
        </motion.h2>
        <motion.p
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          className="text-sm sm:text-lg text-foreground text-center sm:mb-16 mb-4"
        >
          Arcana Vibes provides a personalized readings and insights for each user, based on their unique astrological chart.
        </motion.p>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8"
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className=" flex flex-col items-center text-center"
            >
              {/* <motion.div
                variants={iconVariants}
                className="bg-primary/10 p-3 rounded-full mb-4"
              >
                <feature.icon className="w-8 h-8 text-primary" />
              </motion.div> */}
              <motion.div
                variants={iconVariants}
                className="mb-0 sm:mb-4 text-center flex justify-center"
              >
                <Image src={feature.image} alt={feature.title} width={440} height={440} className="rounded-3xl aspect-square w-4/5 sm:w-full" />
              </motion.div>
              <motion.h3
                variants={iconVariants}
                className="text-sm sm:text-xl font-normal text-foreground mb-2"
              >
                {feature.title}
              </motion.h3>
              <motion.p
                variants={iconVariants}
                className="text-muted-foreground text-xs sm:text-base leading-tight sm:leading-snug"
              >
                {feature.description}
              </motion.p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}