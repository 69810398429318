'use client'

import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Quote, ChevronLeft, ChevronRight } from 'lucide-react'
import Button from '@/components/Button'
import Image from 'next/image'

const testimonials = [
  {
    quote: "The daily tarot draws combined with my birth chart have provided incredibly accurate insights. It's like having a personal spiritual guide at my fingertips.",
    author: "Sarah M.",
    role: "Daily User",
    image: "/testimonials/1.png?height=100&width=100"
  },
  {
    quote: "I love how the readings take into account my astrological placements. The personalized interpretations have helped me navigate major life decisions.",
    author: "Michael R.",
    role: "Premium Member",
    image: "/testimonials/4.png?height=100&width=100"
  },
  {
    quote: "The birth chart data provided by Arcana Vibes is incredibly detailed and accurate. It has given me profound insights into my personality and life path.",
    author: "Julia K.",
    role: "Astrology Enthusiast",
    image: "/testimonials/3.png?height=100&width=100"
  },
  {
    quote: "As someone new to tarot, the AI interpretations have helped me understand the cards in relation to my personal journey. It's been enlightening!",
    author: "Simone W.",
    role: "New Member",
    image: "/testimonials/2.png?height=100&width=100"
  }
]

export default function TestimonialsSection() {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (testimonials.length - 2))
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (testimonials.length - 2))
  }

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length - 2) % (testimonials.length - 2))
  }

  return (
    <section className="pt-16 pb-16 sm:pt-24 sm:pb-0 px-8 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-xl sm:text-3xl font-normal font-heading tracking-tighter text-center mb-4 sm:mb-6"
        >
          What Our Community Says
        </motion.h2>
        <div className="relative">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
              className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8"
            >
              {[0, 1, 2].map((offset) => {
                const testimonial = testimonials[(currentIndex + offset) % testimonials.length]
                return (
                  <div key={offset} className="bg-white backdrop-blur-sm p-6 rounded-3xl shadow-lg relative flex flex-col justify-between">
                    <div className="flex flex-col">
                      <Quote className="absolute top-4 left-4 w-8 h-8 text-primary opacity-20" />
                      <p className="text-primary/80 text-xs sm:text-sm leading-tight sm:leading-snug mb-4 mt-6 italic relative z-10">
                      &ldquo;{testimonial.quote}&rdquo;
                    </p>
                    </div>
                    <div className="flex items-center">
                      <Image 
                        src={testimonial.image} 
                        alt={testimonial.author} 
                        width={48} 
                        height={48} 
                        className="rounded-full mr-3" 
                      />
                      <div>
                        <p className="font-normal text-primary text-xs sm:text-sm">{testimonial.author}</p>
                        <p className="text-xs sm:text-base text-primary/70">{testimonial.role}</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </motion.div>
          </AnimatePresence>
          <Button 
            size="icon" 
            variant="text" 
            onClick={prevTestimonial} 
            className="absolute top-1/2 transform -translate-y-1/2 -left-4 md:-left-12 rounded-full"
          >
            <ChevronLeft className="h-6 w-6" />
            <span className="sr-only">Previous testimonials</span>
          </Button>
          <Button 
            size="icon" 
            variant="text" 
            onClick={nextTestimonial} 
            className="absolute top-1/2 transform -translate-y-1/2 -right-4 md:-right-12 rounded-full"
          >
            <ChevronRight className="h-6 w-6" />
            <span className="sr-only">Next testimonials</span>
          </Button>
        </div>
      </div>
    </section>
  )
}